var testimonial_slider = tns({
    container: '.testimonials--slider',
    items: 1,
    slideBy: 1,
    autoplay: false,
    gutter: 40,
    nav: true,
    controlsText: [
        '<i class="customicon-arrow-left"></i>',
        '<i class="customicon-arrow-right"></i>',
    ],
    onInit: function() { // some accessibility improvements
        document.querySelector('.testimonials .tns-controls').removeAttribute('aria-label');
        document.querySelector('.testimonials .tns-controls button[data-controls="prev"]').setAttribute('aria-label', 'Previous');
        document.querySelector('.testimonials .tns-controls button[data-controls="next"]').setAttribute('aria-label', 'Next');
    }
});